import gql from "graphql-tag";
import { FormularyTier } from "../Flex/Formularies/FormularyPage/types";
import { EditNetworkTier } from "../Network/api";
import { PaginationInput } from "../types/ApiTypes";
import {
  APILineOfBusinessItem,
  APIPlan,
  APIPlanAccumulator,
  APIPlanConfig,
  APIPlanConfigItem,
  APIPlanItem,
  BasicIdNamePair,
  CostShareType,
  PlanAccumulatorDeductible,
  PlanAccumulatorMaxOOP,
  PlanCoverageOption,
  PlanType,
} from "./types";

//#region Plans

//#region List Plans (List View)
export const LIST_PLANS = gql`
  query GetPlans {
    plans {
      items {
        id
        name
        lineOfBusiness {
          name
        }
        planType
        externalId
        effectiveDate
        terminationDate
        notes
      }
    }
  }
`;

export type ListPlansResponse = {
  plans: {
    items: Array<APIPlanItem>;
  };
};
//#endregion

//#region List Plans (Table View)
export const GET_PLANS = gql`
  query GetPlans($paginationInput: PaginationInput) {
    plans(paginationInput: $paginationInput) {
      pageInfo {
        endCursor
      }
      items {
        id
        name
        externalId
        lineOfBusiness {
          name
          id
        }
        planType
        effectiveDate
        calendarYear
        formulary {
          name
        }
        network {
          name
        }
        planConfigName
        accumulator {
          deductible {
            isIntegrated
            isEmbedded
            amountIndividual
            amountFamily
            amountIndividualPlus1
            excludedTiers
          }
          maxOutOfPocket {
            isIntegrated
            isEmbedded
            amountIndividual
            amountFamily
            amountIndividualPlus1
            amountIndividualMailOrder
            excludedTiers
          }
        }
        status
        hierarchies {
          carrierCode
          carrierName
          accountCode
          accountName
          groupCode
          groupName
          effectiveDate
          terminationDate
          contactName
          contactNumber
        }
        createdBy
        createdOn
        updatedBy
        updatedOn
        notes
        planType
        terminationDate
      }
    }
  }
`;

export type GetPlansInput = {
  paginationInput: PaginationInput;
};

export type GetPlansResponse = {
  plans: {
    pageInfo: {
      endCursor: string | null;
    };
    items: Array<APIPlanItem>;
  };
};
//#endregion

//#region Publish Plan

export const PUBLISH_PLAN = gql`
  mutation PublishPlan($id: ID!, $input: UpdatePlanInput!) {
    updatePlan(id: $id, input: $input) {
      ... on Plan {
        id
        name
        status
      }
      ... on Error {
        code
        context
      }
    }
  }
`;

export type PublishPlanResponse = {
  updatePlan: {
    id: string;
    name: string;
    status: string;
  };
};

export type PublishPlanInput = {
  id: string;
  input: {
    status: string;
  };
};

//#endregion

//#region Copy Plan

export const COPY_PLAN = gql`
  mutation CopyPlan($id: ID!, $input: CopyPlanInput!) {
    copyPlan(id: $id, input: $input) {
      ... on PlanItem {
        id
        name
        lineOfBusiness {
          name
          id
        }
        planType
        effectiveDate
        externalId
        terminationDate
        calendarYear
        formulary {
          name
        }
        network {
          name
        }
        planConfigName
        notes
        hierarchies {
          carrierName
          accountName
          groupName
        }
        accumulator {
          deductible {
            isIntegrated
            isEmbedded
            amountIndividual
            amountFamily
            amountIndividualPlus1
            excludedTiers
          }
          maxOutOfPocket {
            isIntegrated
            isEmbedded
            amountIndividual
            amountFamily
            amountIndividualPlus1
            amountIndividualMailOrder
            excludedTiers
          }
        }
        status
        createdBy
        createdOn
        updatedBy
        updatedOn
      }
      ... on Error {
        code
        context
      }
    }
  }
`;

export type CopyPlanResponse = {
  copyPlan: APIPlanItem;
};

export type CopyPlanInput = {
  id: string;
  input: {
    calendarYear?: number | null;
    effectiveDate: string;
    externalId: string | null;
    lineOfBusinessId: string;
    name: string;
    planType: PlanType;
    terminationDate?: string | null;
  };
};

//#endregion

//#region Delete Plan
export const DELETE_PLAN = gql`
  mutation DeletePlan($ids: [ID!]!) {
    deletePlans(ids: $ids) {
      ... on Plan {
        id
        name
      }
      ... on Error {
        code
        context
      }
    }
  }
`;

export type DeletePlanInput = {
  ids: Array<string>;
};

export type DeletePlanResponse = {
  deletePlans: Array<{ id: string; name: string }>;
};
//#endregion

//#region Export Plans
export const GET_PLANS_EXPORT_DATA = gql`
  query GetPlans {
    plans {
      items {
        id
        name
        lineOfBusiness {
          name
        }
        planType
        effectiveDate
        formulary {
          name
        }
        network {
          name
        }
        planConfigName
        accumulator {
          hasDeductible
          deductible {
            isIntegrated
            isEmbedded
            amountIndividual
            amountFamily
            amountIndividualPlus1
            excludedTiers
          }
          hasMaxOutOfPocket
          maxOutOfPocket {
            isIntegrated
            isEmbedded
            amountIndividual
            amountFamily
            amountIndividualPlus1
            amountIndividualMailOrder
            excludedTiers
          }
        }
        status
        createdBy
        createdOn
        updatedBy
        updatedOn
        notes
      }
    }
  }
`;

export type GetExportDataResponse = {
  plans: {
    items: Array<APIPlanItem>;
  };
};
//#endregion

//#region Get Plan
export const GET_PLAN = gql`
  query GetPlan($id: ID!) {
    plan(id: $id) {
      id
      name
      lineOfBusiness {
        id
        name
        isMedicare
      }
      externalId
      configId
      calendarYear
      notes
      effectiveDate
      terminationDate
      rxBIN
      rxPCN
      rxGroup
      planType
      hierarchies {
        carrierId
        accountId
        groupId
      }
      status
    }
  }
`;

export const GET_PLAN_NAME = gql`
  query get($id: ID!) {
    plan(id: $id) {
      name
    }
  }
`;

export type GetPlanNameResponse = {
  plan: {
    name: string;
  };
};

export type GetPlanResponse = {
  plan: APIPlan;
};
//#endregion

//#region Get Plan Accumulators
export const GET_PLAN_ACCUMULATORS = gql`
  query GetPlanAccumulators($planId: ID!) {
    planAccumulator(planId: $planId) {
      id
      planId
      annualResetDate
      hasDeductible
      hasMaxOutOfPocket
      deductible {
        isEmbedded
        isIntegrated
        inNetwork {
          individual
          family
          individualPlus1
        }
        outOfNetwork {
          individual
          family
          individualPlus1
        }
        excludedTiers
      }
      maxOop {
        isEmbedded
        isIntegrated
        inNetwork {
          individual
          family
          individualPlus1
        }
        outOfNetwork {
          individual
          family
          individualPlus1
        }
        mailOrder {
          individual
          family
          individualPlus1
        }
        excludedTiers
      }
    }
  }
`;

export type GetAccumulatorsResponse = {
  planAccumulator: APIPlanAccumulator;
};
//#endregion

//#region List Lines of Business
export const LIST_LOBS = gql`
  query ListLobs {
    linesOfBusiness {
      items {
        id
        name
        isMedicare
      }
    }
  }
`;

export type ListLOBsResponse = {
  linesOfBusiness: {
    items: Array<APILineOfBusinessItem>;
  };
};
//#endregion

//#region Create Plan
export const CREATE_PLAN = gql`
  mutation CreatePlan($input: CreatePlanInput!) {
    createPlan(input: $input) {
      ... on Plan {
        id
        name
        lineOfBusiness {
          id
          name
          isMedicare
        }
        externalId
        configId
        calendarYear
        notes
        effectiveDate
        terminationDate
        rxBIN
        rxPCN
        rxGroup
        planType
        hierarchies {
          carrierId
          accountId
          groupId
        }
      }
      ... on Error {
        code
        context
      }
    }
  }
`;

export type CreatePlanResponse = {
  createPlan: APIPlan;
};

export type CreatePlanInput = {
  input: {
    name: string;
    lineOfBusinessId: string;
    planType: PlanType;
    configId: string;
    effectiveDate: string;
    groupIds: Array<string> | null;
    calendarYear: number | null;
    externalId: string | null;
    terminationDate: string | null;
    rxBIN: string | null;
    rxPCN: string | null;
    rxGroup: string | null;
    notes: string | null;
  };
};
//#endregion

//#region Update Plan
export const UPDATE_PLAN = gql`
  mutation UpdatePlan($id: ID!, $input: UpdatePlanInput!) {
    updatePlan(id: $id, input: $input) {
      ... on Plan {
        id
        name
        lineOfBusiness {
          id
          name
          isMedicare
        }
        externalId
        configId
        calendarYear
        notes
        effectiveDate
        terminationDate
        rxBIN
        rxPCN
        rxGroup
        planType
        hierarchies {
          carrierId
          accountId
          groupId
        }
      }
      ... on Error {
        code
        context
      }
    }
  }
`;

export interface UpdatePlanResponse {
  updatePlan: APIPlan;
}

export interface UpdatePlanInput {
  id: string;
  input: {
    configId?: string | null;
    externalId?: string | null;
    lineOfBusinessId?: string;
    groupIds?: Array<string> | null;
    name?: string | null;
    notes?: string | null;
    rxBIN?: string | null;
    rxGroup?: string | null;
    rxPCN?: string | null;
    effectiveDate?: string;
    terminationDate?: string | null;
    status?: string | null;
    planType?: PlanType;
  };
}
//#endregion

//#region List SubAccount Hierarchies
export const LIST_HIERARCHIES = gql`
  query ListHierarchies {
    planCAGs {
      items {
        carrierId
        carrierCode
        carrierName
        planAccounts {
          accountId
          accountCode
          accountName
          planGroups {
            groupId
            groupCode
            groupName
          }
        }
      }
    }
  }
`;

export const LIST_HIERARCHIES_BY_GROUP_IDS = gql`
  query ListHierarchies($input: PlanCAGsInput) {
    planCAGs(input: $input) {
      items {
        carrierId
        carrierCode
        carrierName
        planAccounts {
          accountId
          accountCode
          accountName
          planGroups {
            groupId
            groupCode
            groupName
          }
        }
      }
    }
  }
`;

export type ListHierarchiesByGroupIdsInput = {
  groupIds: Array<string>;
};

export type PlanGroup = {
  groupId: string;
  groupCode: string;
  groupName: string | null;
};

export type PlanAccount = {
  accountId: string;
  accountCode: string;
  accountName: string | null;
  planGroups: Array<PlanGroup>;
};

export type PlanCarrier = {
  carrierId: string;
  carrierCode: string;
  carrierName: string | null;
  planAccounts: Array<PlanAccount>;
};

export type ListHierarchiesResponse = {
  planCAGs: {
    items: Array<PlanCarrier>;
  };
};
//#endregion

//#region List Plan Accounts
export const LIST_PLAN_ACCOUNTS = gql`
  query ListPlanAccounts($input: PlanAccountsInput!) {
    planAccounts(input: $input) {
      items {
        id
        name
        code
      }
    }
  }
`;

export type ListPlanAccountsResponse = {
  planAccounts: {
    items: Array<BasicIdNamePair>;
  };
};

export type ListPlanAccountsInput = {
  input: {
    planCarrierId: string | null;
  };
};
//#endregion

//#region List Plan Groups
export const LIST_PLAN_GROUPS = gql`
  query ListPlanGroups($input: PlanGroupsInput!) {
    planGroups(input: $input) {
      items {
        id
        name
        code
      }
    }
  }
`;

export type ListPlanGroupsResponse = {
  planGroups: {
    items: Array<BasicIdNamePair>;
  };
};

export type ListPlanGroupsInput = {
  input: {
    planAccountId: string;
  };
};
//#endregion

//#region Get Plan Coverage Options
export const LIST_PLAN_COVERAGE_OPTIONS = gql`
  query ListPlanCoverageOptions($id: ID!) {
    planCoverageOptions(id: $id) {
      coverageOrder
      enumValues
      id
      question
      type
      value
    }
  }
`;
export interface ListCoverageOptionsInput {
  id: string;
}
export interface ListCoverageOptionsResponse {
  planCoverageOptions: Array<PlanCoverageOption>;
}

export const UPDATE_PLAN_COVERAGE_OPTION = gql`
  mutation UpdatePlanCoverageOption($id: ID!, $input: UpdatePlanCoverageOptionInput!) {
    updatePlanCoverageOption(id: $id, input: $input) {
      id
    }
  }
`;
export interface UpdatePlanCoverageOptionInput {
  id: string;
  input: {
    value: string;
  };
}

//#region Save Accumulators
export const SAVE_ACCUMULATORS = gql`
  mutation SaveAccumulators($planId: ID!, $input: SetPlanAccumulatorInput!) {
    setPlanAccumulator(planId: $planId, input: $input) {
      id
    }
  }
`;

export type SaveAccumulatorsInput = {
  planId: string;
  input: {
    annualResetDate: string;
    hasDeductible: boolean;
    hasMaxOutOfPocket: boolean;
    deductible: PlanAccumulatorDeductible | null;
    maxOop: PlanAccumulatorMaxOOP | null;
  };
};
//#endregion

//#endregion

//#endregion

//#region Plan Configs

//#region Delete Plan Config
export const DELETE_PLAN_CONFIG = gql`
  mutation DeletePlanConfig($id: ID!) {
    deletePlanConfig(id: $id) {
      ... on PlanConfig {
        id
        name
      }
      ... on Error {
        code
        context
      }
    }
  }
`;

export type DeletePlanConfigInput = {
  id: string;
};

export type DeletePlanConfigResponse = {
  deletePlanConfig: APIPlanConfigItem;
};
//#endregion

//#region List Plan Config
export const LIST_PLAN_CONFIGS = gql`
  query ListPlanConfigs {
    planConfigs {
      items {
        id
        name
        formularyName
        networkName
        createdBy
        createdOn
        updatedBy
        updatedOn
      }
    }
  }
`;

export type ListPlanConfigsResponse = {
  planConfigs: {
    items: Array<APIPlanConfigItem>;
  };
};
//#endregion

//#region Copy Plan Config

export const COPY_PLAN_CONFIG = gql`
  mutation CopyPlanConfig($id: ID!, $input: CopyPlanConfigInput!) {
    copyPlanConfig(id: $id, input: $input) {
      ... on PlanConfig {
        id
        name
      }
      ... on Error {
        code
        context
      }
    }
  }
`;

export type CopyPlanConfigInput = {
  id: string;
  input: { name: string };
};

export type CopyPlanConfigResponse = {
  copyPlanConfig: APIPlanConfigItem;
};

//#endregion

//#region Get Associated Plans
export const GET_ASSOCIATED_PLANS = gql`
  query GetAssociatedPlans($input: PlansInput) {
    plans(input: $input) {
      items {
        id
        name
        externalId
      }
    }
  }
`;

export interface GetAssociatedPlansResponse {
  plans: {
    items: Array<AssociatedPlanItem>;
  };
}

export interface AssociatedPlanItem {
  id: string;
  name: string;
  externalId: string;
}

//#endregion

//#region Get Plan Config
export const GET_PLAN_CONFIG = gql`
  query GetPlanConfig($id: ID!) {
    planConfig(id: $id) {
      id
      name
      formularyId
      networkId
    }
  }
`;

export type GetPlanConfigResponse = {
  planConfig: APIPlanConfig;
};
//#endregion

//#region List Formularies
export const LIST_FORMULARIES = gql`
  query ListFormularies {
    formularies {
      items {
        id
        name
      }
    }
  }
`;
export type ListFormulariesResponse = {
  formularies: {
    items: Array<{
      id: string;
      name: string;
    }>;
  };
};
//#endregion

//#region List Networks
export const LIST_NETWORKS = gql`
  query ListNetworks {
    networks {
      items {
        id
        name
      }
    }
  }
`;

export type ListNetworksResponse = {
  networks: {
    items: Array<{
      id: string;
      name: string;
    }>;
  };
};
//#endregion

//#region Get Formulary Tiers
export const GET_FORMULARY_TIERS = gql`
  query GetFormularyTiers($id: ID!) {
    formulary(id: $id) {
      tiers {
        items {
          id
          subAccountFormularyTierId
          name
          rank
        }
      }
    }
  }
`;

export type GetFormularyTiersResponse = {
  formulary: {
    tiers: {
      items: Array<FormularyTier>;
    };
  };
};
//#endregion

//#region Get Network Tiers
export const GET_NETWORK_TIERS = gql`
  query GetNetworkTiers($id: ID!) {
    network(id: $id) {
      tiers {
        items {
          id
          name
        }
      }
    }
  }
`;

export type GetNetworkTiersResponse = {
  network: {
    tiers: {
      items: Array<EditNetworkTier>;
    };
  };
};
//#endregion

//#region Plan Config Cost Share Request

export const PLAN_CONFIG_COST_SHARE_DATA = gql`
  query AllCostShareData($formularyId: ID!, $networkId: ID!, $planConfigId: ID!) {
    associatedFormularyTiersInfo: formulary(id: $formularyId) {
      tiers {
        items {
          id
          subAccountFormularyTierId
          name
          rank
        }
      }
    }

    associatedNetworkTiersInfo: network(id: $networkId) {
      tiers {
        items {
          id
          name
          tierOrder
        }
      }
    }

    costShare: planCostShare(planConfigId: $planConfigId) {
      data
    }

    costShareTypes: costShareTypes {
      description
      id
      isMedicare
    }

    benefitsLists: lists(isTermed: false, listType: BENEFITS) {
      items {
        id
        name
      }
    }
  }
`;

export interface GetAllPlanConfigCostShareDataResponse {
  associatedFormularyTiersInfo: { tiers: { items: Array<FormularyTier> } };
  associatedNetworkTiersInfo: { tiers: { items: Array<EditNetworkTier> } };
  costShare: { data: StringifiedJSON };
  costShareTypes: Array<CostShareType>;
  benfitsLists: Array<{ id: string; name: string }>;
}
export interface GetAllPlanConfigCostShareDataInput {
  formularyId: string;
  networkId: string;
  planConfigId: string;
}

//#endregion

//#region Create Plan Config
export const CREATE_PLAN_CONFIG = gql`
  mutation CreatePlanConfig($input: CreatePlanConfigInput!) {
    createPlanConfig(input: $input) {
      ... on PlanConfig {
        id
        name
        formularyId
        networkId
      }
      ... on Error {
        code
        context
      }
    }
  }
`;

export type CreatePlanConfigInput = {
  input: {
    name: string;
    formularyId: string | null;
    networkId: string | null;
  };
};

export type CreatePlanConfigResponse = {
  createPlanConfig: APIPlanConfig;
};
//#endregion

//#region Update Plan Config
export const UPDATE_PLAN_CONFIG = gql`
  mutation UpdatePlanConfig($id: ID!, $input: UpdatePlanConfigInput!) {
    updatePlanConfig(id: $id, input: $input) {
      ... on PlanConfig {
        id
        name
        formularyId
        networkId
      }
      ... on Error {
        code
        context
      }
    }
  }
`;

export type UpdatePlanConfigInput = {
  id: string;
  input: {
    name: string;
    formularyId: string | null;
    networkId: string | null;
  };
};

export type UpdatePlanConfigResponse = {
  updatePlanConfig: APIPlanConfig;
};
//#endregion

//#region Cost Share

export const LIST_COST_SHARE_TYPES = gql`
  query CostShareTypes {
    costShareTypes {
      description
      id
      isMedicare
    }
  }
`;

export interface ListCostShareTypesResponse {
  costShareTypes: Array<CostShareType>;
}

export function getCostShareId(costShareTypes: Array<CostShareType>): string | undefined {
  if (costShareTypes.length === 1) {
    return costShareTypes[0].id;
  } else {
    return costShareTypes.find(
      (costShareType) => costShareType.description.toLowerCase() === "cost share"
    )?.id;
  }
}

export const SET_COST_SHARE = gql`
  mutation SetCostShare($input: CostShareInput!, $planConfigId: ID!) {
    setPlanCostShare(input: $input, planConfigId: $planConfigId) {
      planId
    }
  }
`;

export interface SetCostShareInput {
  planConfigId: string;
  input: {
    typeId: string;
    data: StringifiedJSON;
  };
}

type StringifiedJSON = string;

//#endregion

//#endregion
