import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  fade,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import QuestionIcon from "@material-ui/icons/HelpRounded";
import * as React from "react";
import { SaveFab } from "../../components/buttons/SaveFab";
import { AlertDialog } from "../../components/dialogs/AlertDialog";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { CenteredCircularLoading } from "../../components/loading/CenteredCircularLoading";
import { OptionsTable } from "../../components/tables/OptionsTable";
import { UserContext } from "../../components/UserContext";
import { FormularyTier } from "../../Flex/Formularies/FormularyPage/types";
import { sharedStyles } from "../../Flex/styles";
import { EditNetworkTier } from "../../Network/api";
import {
  CreatePlanConfigInput,
  CreatePlanConfigResponse,
  CREATE_PLAN_CONFIG,
  getCostShareId,
  GetFormularyTiersResponse,
  GetNetworkTiersResponse,
  GET_FORMULARY_TIERS,
  GET_NETWORK_TIERS,
  ListCostShareTypesResponse,
  ListFormulariesResponse,
  ListNetworksResponse,
  LIST_COST_SHARE_TYPES,
  LIST_FORMULARIES,
  LIST_NETWORKS,
  SetCostShareInput,
  SET_COST_SHARE,
  UpdatePlanConfigInput,
  UpdatePlanConfigResponse,
  UPDATE_PLAN_CONFIG,
} from "../api";
import { createDefaultEmptyCostShare } from "../CostShare/CostShareSchema";
import { APIPlanConfig, AWSApolloError, PlanConfigPageMode } from "../types";

const useStyles = makeStyles((theme) => ({
  textFieldOptions: {
    width: "100%",
    maxWidth: "75%",
  },
  fab: {
    color: theme.palette.common.white,
  },
  extendedFab: {
    marginRight: theme.spacing(),
  },
  primaryAvatar: {
    background: theme.palette.primary.main,
  },
  noTiers: {
    fontStyle: "italic",
  },
  marginRightSpacing: {
    marginRight: theme.spacing(),
  },
  technicalErrors: {
    maxHeight: 600,
    overflow: "auto",
  },
  technicalDetailsTable: {
    background: fade(theme.palette.common.black, 0.02),
  },
  errorButtons: {
    color: theme.palette.error.main,
  },
}));

type Props = {
  planConfig: APIPlanConfig;
  mode: PlanConfigPageMode;
  onUpdatePlanConfig: (planConfig: APIPlanConfig) => void;
};

export function PlanConfigSetup(props: Props): JSX.Element {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const {
    user: {
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);

  const newPlanConfig = props.mode === PlanConfigPageMode.NEW;
  const readOnly = props.mode === PlanConfigPageMode.VIEW;

  const [pageErrors, setPageErrors] = React.useState<AWSApolloError | undefined>(undefined);
  const [planConfig, setPlanConfig] = React.useState<APIPlanConfig>(props.planConfig);
  const [formularyTiers, setFormularyTiers] = React.useState<Array<FormularyTier>>([]);
  const [networkTiers, setNetworkTiers] = React.useState<Array<EditNetworkTier>>([]);
  const [costShareTypeId, setCostShareTypeId] = React.useState<string | undefined>(undefined);
  const [isConfirmingCostShareReset, setIsConfirmingCostShareReset] = React.useState(false);

  //#region Apollo Queries
  const { data: allFormularies, loading: loadingFormularies } = useQuery<ListFormulariesResponse>(
    LIST_FORMULARIES,
    {
      onError: (err) => {
        console.error(err);
        setPageErrors(err);
      },
    }
  );
  const { data: allNetworks, loading: loadingNetworks } = useQuery<ListNetworksResponse>(
    LIST_NETWORKS
  );
  useQuery<ListCostShareTypesResponse>(LIST_COST_SHARE_TYPES, {
    onCompleted: (res) => {
      setCostShareTypeId(getCostShareId(res.costShareTypes));
    },
    onError: (err) => {
      console.error(err);
      setPageErrors(err);
    },
  });

  const [getFormularyTiers, { loading: loadingFormularyTiers }] = useLazyQuery<
    GetFormularyTiersResponse,
    { id: string }
  >(GET_FORMULARY_TIERS, {
    onCompleted: (res) => {
      setFormularyTiers(res.formulary.tiers.items);
    },
    onError: (err) => {
      console.error(err);
      setPageErrors(err);
    },
  });
  const [getNetworkTiers, { loading: loadingNetworkTiers }] = useLazyQuery<
    GetNetworkTiersResponse,
    { id: string }
  >(GET_NETWORK_TIERS, {
    onCompleted: (res) => {
      setNetworkTiers(res.network.tiers.items);
    },
    onError: (err) => {
      console.error(err);
      setPageErrors(err);
    },
  });

  const [saveCostShare, { loading: isSavingCostShare }] = useMutation<unknown, SetCostShareInput>(
    SET_COST_SHARE,
    {
      onError: (e) => {
        console.error("error saving cost share data", e);
        setPageErrors(e);
      },
    }
  );
  const [createPlanConfig, { loading: isSaving }] = useMutation<
    CreatePlanConfigResponse,
    CreatePlanConfigInput
  >(CREATE_PLAN_CONFIG, {
    onCompleted: ({ createPlanConfig }) => {
      if (costShareTypeId) {
        handleSaveCostShare(costShareTypeId, createPlanConfig.id).then(() => {
          props.onUpdatePlanConfig(createPlanConfig);
        });
      }
    },
    onError: (err) => {
      console.error(JSON.stringify(err, null, 2));
      setPageErrors(err);
    },
  });
  const [updatePlanConfig, { loading: isUpdating }] = useMutation<
    UpdatePlanConfigResponse,
    UpdatePlanConfigInput
  >(UPDATE_PLAN_CONFIG, {
    onCompleted: () => {
      if (costShareTypeId) {
        handleSaveCostShare(costShareTypeId, props.planConfig.id).then(() => {
          props.onUpdatePlanConfig(planConfig);
        });
      }
    },
    onError: (err) => {
      console.error(err);
      setPageErrors(err);
    },
  });
  //#endregion

  React.useEffect(() => {
    if (props.planConfig.formularyId) {
      getFormularyTiers({ variables: { id: props.planConfig.formularyId } });
    }
    if (props.planConfig.networkId) {
      getNetworkTiers({ variables: { id: props.planConfig.networkId } });
    }
  }, [getFormularyTiers, getNetworkTiers, props.planConfig]);

  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setPlanConfig({ ...planConfig, name: event.target.value });
  }

  function handleSaveCostShare(costShareTypeId: string, planConfigId: string) {
    return saveCostShare({
      variables: {
        planConfigId,
        input: {
          data: JSON.stringify(createDefaultEmptyCostShare(formularyTiers, networkTiers)),
          typeId: costShareTypeId,
        },
      },
    });
  }

  function handleUpdateFormulary(
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) {
    const newFormulary = event.target.value as any;
    setPlanConfig({ ...planConfig, formularyId: newFormulary.id });
    getFormularyTiers({ variables: { id: newFormulary.id } });
  }

  function handleUpdateNetwork(
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) {
    const newNetwork = event.target.value as any;
    setPlanConfig({ ...planConfig, networkId: newNetwork.id });
    getNetworkTiers({ variables: { id: newNetwork.id } });
  }

  function handleSavePlanConfig() {
    if (newPlanConfig && costShareTypeId) {
      createPlanConfig({
        variables: {
          input: {
            name: planConfig.name,
            formularyId: planConfig.formularyId,
            networkId: planConfig.networkId,
          },
        },
      });
    } else {
      setIsConfirmingCostShareReset(true);
    }
  }

  function getFormularyTiersCardTitle() {
    const formularyName = getFormularyName();
    if (planConfig.formularyId !== null && formularyName !== undefined) {
      return `Formulary tiers for ${formularyName}`;
    } else {
      return `Formulary tiers`;
    }
  }

  function getNetworkTiersCardTitle() {
    const networkName = getNetworkName();
    if (planConfig.networkId !== null && networkName !== undefined) {
      return `Network tiers for ${networkName}`;
    } else {
      return `Network tiers`;
    }
  }

  function getFormularyName() {
    if (allFormularies) {
      const foundFormulary = allFormularies?.formularies.items.find(
        (formulary) => formulary.id === planConfig.formularyId
      );
      if (foundFormulary) {
        return foundFormulary.name;
      } else {
        console.warn("No Formulary found for formularyId:", planConfig.formularyId);
        return undefined;
      }
    } else {
      return "";
    }
  }

  function getNetworkName() {
    if (allNetworks) {
      const foundNetwork = allNetworks?.networks.items.find(
        (network) => network.id === planConfig.networkId
      );
      if (foundNetwork) {
        return foundNetwork.name;
      } else {
        console.warn("No network found for networkId:", planConfig.networkId);
        return undefined;
      }
    } else {
      return "";
    }
  }

  const planConfigOptions = [
    {
      label: "Name",
      options: (
        <TextField
          required
          disabled={readOnly}
          value={planConfig.name}
          onChange={handleChangeName}
          className={classes.textFieldOptions}
        />
      ),
    },
    {
      label: "Formulary",
      options: readOnly ? (
        <TextField disabled value={getFormularyName()} className={classes.textFieldOptions} />
      ) : (
        <Select
          required
          value={
            allFormularies?.formularies.items.find(
              (formulary) => formulary.id === planConfig.formularyId
            ) || ""
          }
          onChange={handleUpdateFormulary}
          style={{ textAlign: "left" }}
          className={classes.textFieldOptions}
        >
          {allFormularies?.formularies.items.length === 0 ? (
            <MenuItem key="noFormularies" disabled>
              No saved formularies found.
            </MenuItem>
          ) : loadingFormularies ? (
            <MenuItem key="loadingFormularies" disabled>
              <CircularProgress size={20} className={classes.marginRightSpacing} /> Loading
              Formularies...
            </MenuItem>
          ) : (
            allFormularies?.formularies.items.map((formulary) => (
              <MenuItem key={formulary.id} value={formulary as any}>
                {formulary.name}
              </MenuItem>
            ))
          )}
        </Select>
      ),
    },
    {
      label: "Network",
      options: readOnly ? (
        <TextField disabled value={getNetworkName()} className={classes.textFieldOptions} />
      ) : (
        <Select
          required
          value={
            allNetworks?.networks.items.find((network) => network.id === planConfig.networkId) || ""
          }
          onChange={handleUpdateNetwork}
          style={{ textAlign: "left" }}
          className={classes.textFieldOptions}
        >
          {allNetworks?.networks.items.length === 0 ? (
            <MenuItem key="noNetworks" disabled>
              No saved networks found.
            </MenuItem>
          ) : loadingNetworks ? (
            <MenuItem key="loadingNetowkrs" disabled>
              <CircularProgress size={20} className={classes.marginRightSpacing} /> Loading
              networks...
            </MenuItem>
          ) : (
            allNetworks?.networks.items.map((network) => (
              <MenuItem key={network.id} value={network as any}>
                {network.name}
              </MenuItem>
            ))
          )}
        </Select>
      ),
    },
  ];

  function cancelResetCostShare() {
    setIsConfirmingCostShareReset(false);
  }

  function confirmResetCostShare() {
    updatePlanConfig({
      variables: {
        id: planConfig.id,
        input: {
          name: planConfig.name,
          formularyId: planConfig.formularyId,
          networkId: planConfig.networkId,
        },
      },
    });
    setIsConfirmingCostShareReset(false);
  }

  const hasValidNetworkTierCount = networkTiers !== null && networkTiers.length > 0;
  const isValidToSave =
    !!planConfig.name &&
    !!planConfig.formularyId &&
    !!planConfig.networkId &&
    hasValidNetworkTierCount;

  const shouldDisplayLoadingSpinner = isSaving || isUpdating || isSavingCostShare;

  return (
    <>
      <section className={sharedClasses.tabContent}>
        <SaveFab
          isSaving={shouldDisplayLoadingSpinner}
          isValidToSave={isValidToSave}
          onClickSave={handleSavePlanConfig}
        />
        <Card className={sharedClasses.formularyCard}>
          <CardHeader title="Properties" />
          <Divider />
          <CardContent>
            {loadingFormularies && loadingNetworks ? (
              <CenteredCircularLoading />
            ) : (
              <OptionsTable settings={planConfigOptions} />
            )}
          </CardContent>
        </Card>
        <div>
          <Card className={sharedClasses.formularyCard}>
            <CardHeader title={getFormularyTiersCardTitle()} />
            <Divider />
            <CardContent>
              {loadingFormularyTiers ? (
                <CenteredCircularLoading />
              ) : (
                <List dense={contentSpacing === "dense"}>
                  {planConfig.formularyId === null ? (
                    <ListItem className={classes.noTiers}>
                      <ListItemText primary="No formulary selected." />
                    </ListItem>
                  ) : formularyTiers.length === 0 ? (
                    <ListItem className={classes.noTiers}>
                      <ListItemText primary="No tiers found for this formulary." />
                    </ListItem>
                  ) : (
                    formularyTiers.map((tier) => (
                      <ListItem key={tier.id}>
                        <ListItemAvatar>
                          <Avatar className={classes.primaryAvatar}>{`T${tier.rank}`}</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={tier.name} />
                      </ListItem>
                    ))
                  )}
                </List>
              )}
            </CardContent>
          </Card>
          <Card className={sharedClasses.formularyCard}>
            <CardHeader title={getNetworkTiersCardTitle()} />
            <Divider />
            <CardContent>
              {loadingNetworkTiers ? (
                <CenteredCircularLoading />
              ) : (
                <List dense={contentSpacing === "dense"}>
                  {planConfig.networkId === null ? (
                    <ListItem className={classes.noTiers}>
                      <ListItemText primary="No network selected." />
                    </ListItem>
                  ) : networkTiers.length === 0 ? (
                    <ListItem className={classes.noTiers}>
                      <ListItemText primary="No tiers found for this network." />
                    </ListItem>
                  ) : (
                    networkTiers.map((network) => (
                      <ListItem key={network.id}>
                        <ListItemText primary={network.name} />
                      </ListItem>
                    ))
                  )}
                </List>
              )}
            </CardContent>
          </Card>
        </div>
        <ConfirmationDialog
          isOpen={isConfirmingCostShareReset}
          onNoHandler={cancelResetCostShare}
          onYesHandler={confirmResetCostShare}
          dialogIcon={<QuestionIcon fontSize="large" color="inherit" />}
          dialogTitle="Warning"
          dialogContent={
            <Typography variant="body1">
              Updating the Plan Config will reset the associated Cost Share. Continue?
            </Typography>
          }
        />
      </section>
      <AlertDialog
        isError
        isOpen={Boolean(pageErrors)}
        dialogTitle={"Error"}
        onExitHandler={() => {
          setPageErrors(undefined);
        }}
        error={pageErrors}
      />
    </>
  );
}
